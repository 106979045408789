// Body
$body-bg: white;
$dark: #000000 !default;
$warning: #FF0080 !default;

//@font-face {
//    font-family: 'HeliosCondLight';
//    src: url('../../public/fonts/helioscondlight.eot'); /* IE 9 Compatibility Mode */
//    src: url('../../public/fonts/helioscondlight.eot'); /* IE 9 Compatibility Mode */
//    src: url('../../public/fonts/helioscondlight.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
//    url('../../public/fonts/helioscondlight.woff2') format('woff2'), /* Super Modern Browsers */
//    url('../../public/fonts/helioscondlight.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
//    url('../../public/fonts/helioscondlight.ttf') format('truetype'), /* Safari, Android, iOS */
//    url('../../public/fonts/helioscondlight.svg#helioscondlight') format('svg'); /* Chrome < 4, Legacy iOS */
//}


// Typography
//$font-family-sans-serif: 'Open Sans Condensed', sans-serif;
$font-family-sans-serif: 'PT Sans Narrow', sans-serif;
$font-size-base: 1.25rem;
$line-height-base: 1.45;

//// Colors
//$blue: #3490dc;
//$indigo: #6574cd;
//$purple: #9561e2;
//$pink: #f66d9b;
//$red: #e3342f;
//$orange: #f6993f;
//$yellow: #ffed4a;
//$green: #38c172;
//$teal: #4dc0b5;
//$cyan: #6cb2eb;
